import io from 'socket.io-client';
import $ from 'jquery';

const socket = io.connect('https://cci.gat.international',{secure: true,    rejectUnauthorized: false});

console.log('main_cci geladen');

socket.on('connect', function() {
    console.log('verbindung aus der Index zum Server besteht');

    socket.on('validUsername', function() {

    });
});